import { graphql } from 'gatsby';
import React, { useState } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { useKey } from 'react-use';
import useFetch from 'use-http';
import { md2react } from 'helpers/parser';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import DownloadModal from 'components/organisms/DownloadModal';
import ResourceGrid from 'components/molecules/ResourceGrid';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';
import * as WatchIcon from 'images/watch.inline.svg';
import useHubspot from 'hooks/use-hubspot';

export interface ReportFormValues {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
}

const initialValues: ReportFormValues = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
};

const schema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  email: Yup.string()
    .required('This field is required')
    .email('Please provide a valid email address'),
  company: Yup.string().required('This field is required'),
});

const Form = ({ file, ctaLabel }) => {
  const { post, error, response } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/4fe7015f-f22d-4698-89ab-90058e3e6e38',
  );

  const gtmVariables = {
    event: 'Lead Form Submission Succeeded',
    'gtm.elementClasses': 'DataReport',
    'gtm.elementId': 'lead-form-data-report-download',
  };

  const {
    values: { firstname, lastname, email, company },
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useHubspot({ initialValues, schema, post, file, gtmVariables });

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500">{errors[name]}</div>
    ) : null;
  };

  return (
    <div className="DataReport">
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
          <div className="w-full lg:w-1/2">
            <input
              className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
              type="text"
              name="firstname"
              value={firstname}
              placeholder="First Name"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage name="firstname" />
          </div>
          <div className="w-full lg:w-1/2">
            <input
              className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
              type="text"
              name="lastname"
              value={lastname}
              placeholder="Last Name"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage name="lastname" />
          </div>
        </div>
        <div>
          <input
            className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
            type="email"
            name="email"
            value={email}
            placeholder="Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name="email" />
        </div>
        <div>
          <input
            className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
            type="text"
            name="company"
            value={company}
            placeholder="Company"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name="company" />
        </div>
        <button
          type="submit"
          className="mb-16 u-btn u-btn--dark focus:outline-none"
        >
          {ctaLabel}
        </button>
        {error && <div className="text-red-500">{response.data?.message}</div>}
      </form>
    </div>
  );
};

const ReportContent = ({
  title,
  description,
  readTime,
  heroImage,
  body,
  onDownloadClick, // not avaiable from preview
  status,
  file,
  ctaLabel,
}) => {
  return (
    <>
      <div className={`bg-aqua bg-opacity-15 pt-32 md:pt-48 pb-48`}>
        <div
          className={cn(
            'px-6 flex flex-col items-center lg:flex-row',
            status === 'public' ? 'u-container' : 'space-x-3 lg:px-12 xl:px-28',
          )}
        >
          <div
            className={cn(
              'w-full mb-16 lg:mb-0',
              status === 'public' ? 'lg:w-1/2' : 'lg:w-2/5',
            )}
          >
            {status === 'public' && <ResourceTypeTag type="report" />}
            <h1 className="u-h1 mt-6 md:mt-8 mb-3.5 w-article max-w-full">
              {title}
            </h1>
            <p
              className={cn(
                'mb-8 text-xl leading-8 tracking-tighter text-mid-grey',
                status === 'public' && 'lg:pr-44',
              )}
            >
              {description}
            </p>
            {status === 'unlisted' && <Form file={file} ctaLabel={ctaLabel} />}
            {status === 'public' && (
              <button
                className="mb-16 u-btn u-btn--dark focus:outline-none"
                onClick={onDownloadClick}
              >
                {ctaLabel}
              </button>
            )}
            {!!readTime && (
              <div className="flex items-center text-sm font-bold tracking-tighter uppercase">
                <WatchIcon className="h-3.5 w-auto mr-2.5" />
                <div>{readTime}</div>
              </div>
            )}
          </div>
          <div
            className={cn(
              'flex items-center justify-center w-full',
              status === 'public' ? 'lg:w-1/2' : 'lg:w-3/5',
            )}
          >
            <img
              src={heroImage}
              alt={title}
              className={cn(
                status === 'public'
                  ? 'h-auto rounded-r-5 xl:w-89 lg:w-110'
                  : 'max-h-150',
              )}
            />
          </div>
        </div>
      </div>

      {body && (
        <div
          className={cn(
            'px-6 u-section',
            status === 'public' ? ' u-container' : 'lg:pl-13 xl:pl-44',
          )}
        >
          <div className="prose-xl lg:w-8/12">{md2react(body)}</div>
        </div>
      )}
    </>
  );
};

export const ReportPreview = ({ entry }) => {
  return <ReportContent {...entry.get('data').toJS()} />;
};

const ReportPage = ({
  data: {
    markdownRemark: { frontmatter: report, rawMarkdownBody },
    page: { frontmatter: page },
    related,
  },
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  useKey('Escape', () => setModalOpen(false));

  const { status, seo, title, description } = report;

  // used by both DownloadModal and ResourceGrid
  const resources = related.edges.map(({ node }) => {
    const { title, slug, heroImage, description } = node.frontmatter || {};
    return {
      title,
      url: `/reports/${slug}`,
      thumbnailUrl: heroImage,
      type: 'download',
      excerpt: description,
    };
  });

  switch (status) {
    case 'unlisted':
      return (
        <Layout simple>
          <SEO
            title={seo?.title || title}
            description={seo?.description || description}
            openGraphImage={seo?.openGraphImage}
          />
          <ReportContent
            {...report}
            body={rawMarkdownBody}
            onDownloadClick={() => setModalOpen(true)}
          />
        </Layout>
      );
    default:
      return (
        <Layout>
          <SEO
            title={seo?.title || title}
            description={seo?.description || description}
            openGraphImage={seo?.openGraphImage}
          />

          <DownloadModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            download={report}
            relatedDownloads={resources}
            downloadModal={page?.downloadModal}
            status={status}
          />

          <ReportContent
            {...report}
            body={rawMarkdownBody}
            onDownloadClick={() => setModalOpen(true)}
          />

          <NewsletterCtaSection
            headline={page?.newsletterCta?.headline}
            background="bg-aqua text-white"
          />

          {resources.length > 0 && (
            <ResourceGrid
              lead="Related Reports"
              cols={3}
              videoIconInverted
              noTag
              resources={resources}
            />
          )}

          <CtaSection headline={page?.cta?.headline} />
        </Layout>
      );
  }
};

export const pageQuery = graphql`
  query ReportByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        status
        title
        date
        file
        description
        readTime
        ctaLabel
        heroImage
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
    page: markdownRemark(fileAbsolutePath: { regex: "/pages/reports.md/" }) {
      frontmatter {
        downloadModal {
          title
          body
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    related: allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: { templateKey: { eq: "report" }, status: { eq: "public" } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default ReportPage;
