import React from 'react';
import { Link } from 'gatsby';
import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import OutsideClickHandler from 'react-outside-click-handler';
import useFetch from 'use-http';
import { md2react } from 'helpers/parser';
import Checkbox from 'components/atoms/Checkbox';
import useHubspot from 'hooks/use-hubspot';

export interface DownloadModalValues {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  terms: boolean;
}

const initialValues: DownloadModalValues = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  terms: false,
};

const schema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  email: Yup.string()
    .required('This field is required')
    .email('Please provide a valid email address'),
  company: Yup.string().required('This field is required'),
  terms: Yup.bool().oneOf([true], 'You need to accept the privacy policy'),
});


const DownloadModal = ({
  modalOpen,
  setModalOpen,
  download,
  downloadModal,
  relatedDownloads,
  status,
}) => {
  const { post, error, response } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/4fe7015f-f22d-4698-89ab-90058e3e6e38',
  );

  const gtmVariables = {
    event: 'Lead Form Submission Succeeded',
    'gtm.elementClasses': 'DataReport',
    'gtm.elementId': 'lead-form-data-report-download',
  }

  const {
    values: { firstname, lastname, email, company, terms },
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useHubspot({ initialValues, schema, post, gtmVariables});

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500">{errors[name]}</div>
    ) : null;
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {modalOpen && (
        <motion.div
          className="fixed inset-0 z-30 flex items-center justify-center p-8 bg-opacity-75 bg-dark-grey lg:p-0"
          initial="off"
          animate="on"
          exit="off"
          variants={{ off: { opacity: 0 }, on: { opacity: 1 } }}
        >
          <div className="max-w-full max-h-full overflow-y-auto bg-white w-modal DataReport">
            <OutsideClickHandler onOutsideClick={() => setModalOpen(false)}>
              <div className="flex flex-col lg:flex-row">
                <div className="flex items-center justify-center w-full py-16 lg:w-5/12 bg-yellow bg-opacity-15 lg:py-0">
                  <img
                    src={download?.heroImage}
                    alt={download?.title}
                    className="w-32 h-auto rounded-r-5 lg:w-52"
                  />
                </div>
                <div className="w-full p-8 bg-white lg:w-7/12 lg:p-16">
                  <h2 className="font-bold text-3.5xl leading-none mb-4">
                    {downloadModal?.title}
                  </h2>
                  <div className="mb-4 text-lg leading-8 tracking-tighter">
                    {md2react(downloadModal?.body)}
                  </div>

                  {response?.ok ? (
                    <div>
                      <a
                        href={download?.file}
                        className="mb-16 u-btn u-btn--dark focus:outline-none"
                      >
                        Get the download now
                      </a>

                      {status === 'public' && (
                        <>
                          <h3 className="mb-6 font-semibold tracking-tighter">
                            Related Downloads
                          </h3>
                          <div className="flex space-x-6">
                            {relatedDownloads.map(
                              ({ title, thumbnailUrl, url }, index) => (
                                <Link
                                  to={url}
                                  className="flex items-center justify-center w-1/2 py-6 transition bg-light-grey hover:opacity-75"
                                  key={index}
                                >
                                  <img
                                    src={thumbnailUrl}
                                    alt={title}
                                    className="w-auto shadow-lg h-14"
                                  />
                                </Link>
                              ),
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <form className="space-y-6" onSubmit={handleSubmit}>
                      <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
                        <div className="w-full lg:w-1/2">
                          <input
                            className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
                            type="text"
                            name="firstname"
                            value={firstname}
                            placeholder="First Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="firstname" />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <input
                            className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
                            type="text"
                            name="lastname"
                            value={lastname}
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="lastname" />
                        </div>
                      </div>
                      <div>
                        <input
                          className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
                          type="email"
                          name="email"
                          value={email}
                          placeholder="Email Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="email" />
                      </div>
                      <div>
                        <input
                          className="w-full px-5 py-2.5 border leading-8 h-auto border-border-grey rounded-lg"
                          type="text"
                          name="company"
                          value={company}
                          placeholder="Company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="company" />
                      </div>
                      <div>
                        <div className="flex items-center tracking-tighter text-mid-grey">
                          <Checkbox
                            className="mr-4 rounded"
                            checked={terms}
                            onClick={() => setFieldValue('terms', !terms)}
                          />
                          I agree to Skupos’{' '}
                          <Link
                            className="ml-1 underline text-paragraph"
                            to="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </Link>
                          .
                        </div>
                        <ErrorMessage name="terms" />
                      </div>
                      <button
                        type="submit"
                        className="mb-16 u-btn u-btn--dark focus:outline-none"
                      >
                        Download now
                      </button>
                      {error && (
                        <div className="text-red-500">
                          {response.data?.message}
                        </div>
                      )}
                    </form>
                  )}
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DownloadModal;
